import $ from 'jquery';

const showSidebarOnDesktop = (filtersSidebar, navigationSidebar) => {
    let buttons = null;

    if ('dsSidebarBreakpoint' in window === false) {
        return;
    }

    // By default on mobile all sidebar should be hidden. If use CSS I have problem with scroller in the filters.
    if (window.innerWidth < window.dsSidebarBreakpoint) {
        if (navigationSidebar && navigationSidebar.length) {
            navigationSidebar[0].classList.add('is-hidden');
        }

        if (filtersSidebar && filtersSidebar.length) {
            filtersSidebar[0].classList.add('is-hidden');
        }
    }

    if (window.innerWidth < window.dsSidebarBreakpoint) {
        return;
    }

    if (filtersSidebar && filtersSidebar.length && !filtersSidebar[0].classList.contains('is-hidden')) {
        const filtersSidebarStyle = window.getComputedStyle(filtersSidebar[0]);

        if (navigationSidebar && navigationSidebar.length) {
            navigationSidebar[0].classList.add('is-hidden');
        }

        if (filtersSidebarStyle.visibility !== "visible"
            || filtersSidebarStyle.display === "none"
        ) {
            return;
        }

        buttons = document.querySelectorAll('[data-sidebar-toggle="filters"]');

        if (buttons.length) {
            buttons[0].classList.add('is-active');
        }

        document.body.classList.add('js-page-has-active-sidebar');
    } else if (navigationSidebar && navigationSidebar.length) {
        const navigationSidebarStyle = window.getComputedStyle(navigationSidebar[0]);

        if (filtersSidebar && filtersSidebar.length && !filtersSidebar[0].classList.contains('is-hidden')) {
            filtersSidebar[0].classList.add('is-hidden');
        }

        if (navigationSidebarStyle.visibility !== "visible" || navigationSidebarStyle.display === "none") {
            return;
        }

        buttons = document.querySelectorAll('[data-sidebar-toggle="main"]');

        if (buttons.length) {
            buttons[0].classList.add('is-active');
        }
        navigationSidebar[0].classList.remove('is-hidden');

        document.body.classList.add('js-page-has-active-sidebar');
    }
};

const initSidebar = () => {
    const $body = $('body');

    if ('dsSidebarBreakpoint' in window) {
        $body.on('click', '[data-hide-sidebar]', (e) => {
            const $this = $(e.currentTarget);
            const sidebarName = $this.attr('data-hide-sidebar');

            $(`[data-sidebar="${sidebarName}"]`).removeClass('is-active').addClass('is-hidden');
            $body.removeClass('js-page-has-active-sidebar no-scrolling');

            // Remove button active state
            const $sidebarOpenButton = $(`[data-sidebar-toggle="${sidebarName}"]`);
            $sidebarOpenButton.removeClass('is-active');

            const sidebarHideEvent = document.createEvent('Event');
            sidebarHideEvent.initEvent('sidebar:hide', false, true);
            document.dispatchEvent(sidebarHideEvent);
        });

        $body.on('click', '[data-sidebar-toggle]', (e) => {
            const $this = $(e.currentTarget);
            const sidebarName = $this.attr('data-sidebar-toggle');
            const $sideBar = $(`[data-sidebar="${sidebarName}"]`);

            if ($sideBar.hasClass('is-active') || parseInt($sideBar.css('opacity'), 10) === 1) {
                $sideBar.removeClass('is-active').addClass('is-hidden');
                $body.removeClass('js-page-has-active-sidebar no-scrolling');

                // Remove button active state
                const $sidebarOpenButton = $(`[data-sidebar-toggle="${sidebarName}"]`);
                $sidebarOpenButton.removeClass('is-active');

                // Trigger event sidebar:hide
                const sidebarShowEvent = document.createEvent('Event');
                sidebarShowEvent.initEvent('sidebar:hide', false, true);
                document.dispatchEvent(sidebarShowEvent);
            } else {
                $('[data-sidebar]').addClass('is-hidden').removeClass('is-active');
                $('[data-sidebar-toggle].is-active').removeClass('is-active');

                // Remove button active state
                const $sidebarOpenButton = $(`[data-sidebar-toggle="${sidebarName}"]`);
                $sidebarOpenButton.addClass('is-active');

                $sideBar.removeClass('is-hidden').addClass('is-active');
                $body.addClass('js-page-has-active-sidebar');

                if (window.innerWidth < window.dsSidebarBreakpoint) {
                    document.body.classList.add('no-scrolling');
                }

                // Trigger event sidebar:show
                const sidebarHideEvent = document.createEvent('Event');
                sidebarHideEvent.initEvent('sidebar:show', false, true);
                document.dispatchEvent(sidebarHideEvent);
            }
        });

        $body.on('click', '[data-sidebar-show]', (e) => {
            const $this = $(e.currentTarget);
            const sidebarName = $this.attr('data-sidebar-show');
            const $sideBar = $(`[data-sidebar="${sidebarName}"]`);

            if (!$sideBar.hasClass('is-active')) {
                $('[data-sidebar]').addClass('is-hidden').removeClass('is-active');
                $('[data-sidebar-toggle].is-active').removeClass('is-active');

                $sideBar.removeClass('is-hidden').addClass('is-active');
                $body.addClass('js-page-has-active-sidebar');

                // Remove button active state
                const $sidebarOpenButton = $(`[data-sidebar-toggle="${sidebarName}"]`);
                $sidebarOpenButton.addClass('is-active');

                if (window.innerWidth < window.dsSidebarBreakpoint) {
                    document.body.classList.add('no-scrolling');
                }

                const sidebarHideEvent = document.createEvent('Event');
                sidebarHideEvent.initEvent('sidebar:show', false, true);
                document.dispatchEvent(sidebarHideEvent);
            }
        });

        const filtersSidebar = document.querySelectorAll('[data-sidebar="filters"]');
        const navigationSidebar = document.querySelectorAll('[data-sidebar="main"]');

        $(window).resize(() => {
            if ('dsTabletBreakpoint' in window
                && window.innerWidth >= window.dsTabletBreakpoint
                && document.body.classList.contains('js-page-has-active-page-builder') === false) {
                showSidebarOnDesktop(filtersSidebar, navigationSidebar);
            }
        });

        $('[data-subordinate]').each((index, element) => {
            const $this = $(element);

            $this.bind('change', (e) => {
                const subordinateTo = e.currentTarget.getAttribute('data-subordinate');
                const primarySubordinateTo = e.currentTarget.getAttribute(
                    'data-primary-subordinate'
                );

                if (e.currentTarget.getAttribute('type') === 'radio') {
                    $('[data-owner]').removeClass('active');
                }

                if ($(e.currentTarget).is(':checked')) {
                    if (subordinateTo) {
                        $(`[data-owner="${subordinateTo}"]`).addClass('active');
                    }

                    if (primarySubordinateTo) {
                        $(`[data-owner="${primarySubordinateTo}"]`).addClass('active');
                    }
                }
            });

            if ($this.is(':checked')) {
                $this.trigger('change');
            }
        });
    }
};

export {showSidebarOnDesktop , initSidebar};
