import noUiSlider from 'nouislider';

export default (sliders) => {
    for (let s=0; s < sliders.length; s+=1) {
        if ('sliderInitialized' in sliders[s] === false) {
            const sliderInput = sliders[s];
            sliderInput.sliderInitialized = true;
            sliderInput.style.display = 'none';

            const sliderHolder = sliderInput.parentElement;
            const oldLabel = sliderHolder.getElementsByTagName('label');
            if (oldLabel[0]) {
                sliderHolder.removeChild(oldLabel[0]);
            }

            const slider = document.createElement('div');
            sliderHolder.appendChild(slider);

            const label = document.createElement('div');
            label.innerHTML = `${sliderInput.placeholder} <strong>${sliderInput.value}</strong>`;

            sliderHolder.insertBefore(label, slider);

            let step = 1;
            let stepType = 'int';
            if (sliderInput.getAttribute('data-step')) {
                step = Number(sliderInput.getAttribute('data-step'));
            }

            if (step % 1 !== 0) {
                stepType = 'float';
            }

            let suffix = '';
            if (sliderInput.getAttribute('data-value-suffix')) {
                suffix = sliderInput.getAttribute('data-value-suffix');
            }

            noUiSlider.create(slider, {
                animate: true,
                animationDuration: 300,
                step,
                connect: [true, false],
                behaviour: 'tap-drag',
                start: parseFloat(sliderInput.value),
                range: {
                    'min': parseInt(sliderInput.getAttribute('data-min'), 10),
                    'max': parseInt(sliderInput.getAttribute('data-max'), 10)
                }
            });

            slider.noUiSlider.on('change', (values) => {
                if (stepType === 'int') {
                    sliderInput.value = `${parseInt(values[0], 10)}${suffix}`;
                } else {
                    sliderInput.value = `${parseFloat(values[0])}${suffix}`;
                }

                label.innerHTML = `${sliderInput.placeholder} <strong>${sliderInput.value}</strong>`;

                if (slider.scheduledRequestTimeoutId !== null) {
                    clearTimeout(slider.scheduledRequestTimeoutId);
                    slider.scheduledRequestTimeoutId = null;
                }

                slider.scheduledRequestTimeoutId = setTimeout(() => {
                    const changeEvent = document.createEvent('Event');
                    changeEvent.initEvent('change', false, true);
                    sliderInput.dispatchEvent(changeEvent);
                }, 2000);

            });

            sliderInput.addEventListener('refreshValue', () => {
                label.innerHTML = `${sliderInput.placeholder} <strong>${sliderInput.value}</strong>`;
                slider.noUiSlider.set(sliderInput.value);
            });
        }
    }
}