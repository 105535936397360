import strftime from 'strftime';

class Language {
    constructor()
    {
        this.data = {};
        this.dateFormat = '%Y-%m-%d';
        this.dateTimeFormat = '%Y-%m-%dT%H:%M:%S';
        this.url = '';
        this.endpoint = ''

        if ('dsLanguage' in window) {
            if (window.dsLanguage.endpoint) {
                this.endpoint = window.dsLanguage.endpoint;
            }

            if (window.dsLanguage.dateFormat) {
                this.dateFormat = window.dsLanguage.dateFormat;
            }

            if (window.dsLanguage.dateTimeFormat) {
                this.dateTimeFormat = window.dsLanguage.dateTimeFormat;
            }

            if (window.dsLanguage.url) {
                this.url = window.dsLanguage.url;
            }
        }
    }

    t(key, options)
    {
        if (key in this.data) {
            return this.translate(key, options);
        }

        return key;
    }

    regex(key)
    {
        const t = this.t(key);

        if (t[0] !== '/') {
            throw new Error(`Invalid regular expression: ${t}`);
        }

        const regexEnd = t.lastIndexOf('/');

        if (regexEnd < 1) {
            throw new Error(`Invalid regular expression: ${t}`);
        }

        return new RegExp(
            t.substring(1, regexEnd),
            t.substring(regexEnd + 1, t.length)
        );
    }

    // Only simple variables are supported
    translate(key, opts)
    {
        let options = opts;

        if (typeof options !== 'object') {
            options = {};
        }

        let translated = [];

        if ('translation' in this.data[key]) {
            translated = this.data[key].translation;
        }

        if (this.data[key].variables && this.data[key].variables.length > 0) {
            for (let i = 0; i < this.data[key].variables.length; i+=1) {
                if (!(this.data[key].variables[i] in options)) {
                    throw new Error(`Variable ${this.data[key].variables[i]} is not set for translation ${this.data[key].key}`);
                }
                translated = translated.replace(
                    `@${this.data[key].variables[i]}`,
                    options[this.data[key].variables[i]]
                );
            }
        }

        return translated;
    }

    parseDate(date)
    {
        if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/i.test(date)) {
            const newDate = new Date(date);
            newDate.setHours(0);
            return newDate;
        }
        return strftime(this.dateFormat, new Date(date));
    }

    formatDate(date)
    {
        return strftime(this.dateFormat, date);
    }

    formatDateTime(date)
    {
        return strftime(this.dateTimeFormat, date);
    }

    fetch()
    {
        if (!this.endpoint || this.endpoint === '') {
            return;
        }

        let cached = null;
        if (window.localStorage !== undefined) {
            cached = localStorage.getItem(this.endpoint);
        }

        if (!cached) {
            fetch(this.endpoint)
                .then(response => response.json())
                .then(response => {
                    this.data = response;

                    const arr = [];
                    
                    for (let i = 0; i < localStorage.length; i+=1){
                        const item = localStorage.key(i);
                        if (item.startsWith(this.endpoint.substring(0,10))) {
                            arr.push(localStorage.key(i));
                        }
                    }

                    arr.forEach(item => {
                        localStorage.removeItem(item);
                    });
                    
                    if (window.localStorage !== undefined) {
                        localStorage.setItem(this.endpoint, JSON.stringify(response));
                    }
                })
        } else {
            this.data = JSON.parse(cached);
        }
    }
}



const Lang = new Language();

if (!window.isCrawler) {
    Lang.fetch();
}

strftime.localize({
    days: [
        Lang.t('days_of_week.Sunday'),
        Lang.t('days_of_week.Monday'),
        Lang.t('days_of_week.Tuesday'),
        Lang.t('days_of_week.Wednesday'),
        Lang.t('days_of_week.Thursday'),
        Lang.t('days_of_week.Friday'),
        Lang.t('days_of_week.Saturday')
    ],
    shortDays: [
        Lang.t('days_of_week.Sun'),
        Lang.t('days_of_week.Mon'),
        Lang.t('days_of_week.Tue'),
        Lang.t('days_of_week.Wed'),
        Lang.t('days_of_week.Thu'),
        Lang.t('days_of_week.Fri'),
        Lang.t('days_of_week.Sat')
    ],
    months: [
        Lang.t('months.January'),
        Lang.t('months.February'),
        Lang.t('months.March'),
        Lang.t('months.April'),
        Lang.t('months.May'),
        Lang.t('months.June'),
        Lang.t('months.July'),
        Lang.t('months.August'),
        Lang.t('months.September'),
        Lang.t('months.October'),
        Lang.t('months.November'),
        Lang.t('months.December')
    ],
    shortMonths: [
        Lang.t('months.Jan'),
        Lang.t('months.Feb'),
        Lang.t('months.Mar'),
        Lang.t('months.Apr'),
        Lang.t('months.May'),
        Lang.t('months.Jun'),
        Lang.t('months.Jul'),
        Lang.t('months.Aug'),
        Lang.t('months.Sep'),
        Lang.t('months.Oct'),
        Lang.t('months.Nov'),
        Lang.t('months.Dec')
    ],
    AM: 'AM',
    PM: 'PM',
    am: 'am',
    pm: 'pm',
    formats: {
        D: '%m/%d/%y',
        F: '%Y-%m-%d',
        R: '%H:%M',
        X: '%T',
        c: '%a %b %d %X %Y',
        r: '%I:%M:%S %p',
        T: '%H:%M:%S',
        v: '%e-%b-%Y',
        x: '%D'
    }
});

export default Lang;
