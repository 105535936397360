import $ from 'jquery';
import InitializerEventDispatcher from '../../utils/InitializerEvent';
import Language from "../../utils/Language";
import Lang from "../../utils/Language";

window.SuggestionRequestDraftProductAction = (data) => {
    let urlPrefix = `/${Language.url}`;

    if ('dsDomain' in window && window.dsDomain === 'cms') {
        urlPrefix = '/cms/';
    }

    $.ajax({
        url: `${urlPrefix}documents/basket/products`,
        data: {
            productId: data.id
        },
        type: 'post',
        dataType: 'json',
        success: (result) => {
            import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                module.default(result);
            });
        }
    });
};


window.reloadBasket = (data) => {
    if (!data) {
        return;
    }

    if ('label' in data) {
        $('#cart-header-label').html(decodeURIComponent(data.label));
    }

    if ('delivery_addresses' in data) {
        $('.js--basket-delivery-addresses').html(decodeURIComponent(data.delivery_addresses));
    }

    if ('payment_information' in data) {
        $('.js--basket-payment-information').html(decodeURIComponent(data.payment_information));
    }

    if ('prices' in data) {
        $('.js--basket-prices').html(decodeURIComponent(data.prices));
    }

    if ('products_table' in data) {
        $('.js--basket-products-table').html(decodeURIComponent(data.products_table));
    }

    if ('user_registration' in data) {
        $('.js--basket-user-registration').html(decodeURIComponent(data.user_registration));
    }

    import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
        if (module.ModalBox.loadingEl) {
            module.ModalBox.loadingEl.style.display = 'none';
        }
    });

    InitializerEventDispatcher.dispatchEvent('ContentChanged');

};

export default () => {
    const $body = $('body');

    let urlPrefix = `/${Language.url}`;

    if ('dsDomain' in window && window.dsDomain === 'cms') {
        urlPrefix = '/cms/';
    }

    let formIsChange = false;

    const autosave = () => {
        if (!formIsChange) {
            return;
        }

        import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
            if (module.ModalBox.loadingEl) {
                module.ModalBox.loadingEl.removeAttribute('style');
            }
        });

        const basketForm = document.getElementById('basket_form');

        if (basketForm) {
            basketForm.FormHandler.process('PUT', {
                Accept: 'application/json',
                'Cache-Control': 'no-cache',
                'X-Requested-With': 'XMLHttpRequest',
                'X-Ajax-Request': 1
            });
            formIsChange = false;
        } else {
            import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
                module.ModalBox.open(`${urlPrefix}documents/basket`)
            });
        }
    }

    window.addEventListener('modal-box:show', () => {
        const basketForm = document.getElementById('basket_form');

        if (!basketForm) {
            return;
        }

        basketForm.addEventListener('input', () => {
            formIsChange = true;
        });
    });

    $body.on('change', '#basket_payment_holder input[type="radio"], [name="basket[delivery_method]"], select[name="basket[address][city]"], [name="basket[address][address]"], [name="basket[address][country_code]"]', {}, () => {
        autosave();
    });

    $body.on('click', '.checkout-user-data a[data-toggle="collapse"]', {}, () => {
        autosave();
    });


    $body.on('click', '.js-next-step', (event) => {
        const $this = $(event.currentTarget);
        const $current = $this.closest('.accordion');
        const $next = $current.next('.accordion');

        if ($current && $current.find('.is-required').length > 0) {
            import(/* webpackChunkName: "tooltip-js", webpackPreload: true */ 'tooltip.js').then(module => {
                event.currentTarget.tooltip = new module.default(event.currentTarget.parentNode, {
                    title: Lang.t('error.required'),
                    trigger: "hover",
                    placement: 'top'
                });

                event.currentTarget.tooltip.show();
            });

            return;
        }

        if ('tooltip' in event.currentTarget) {
            event.currentTarget.tooltip.dispose();
        }

        if ($next.length) {
            autosave();
            $next.find('[role="button"]').trigger('click');
        }
    });

    $body.on('click', '[data-buy-product]', {}, (e) => {
        $.ajax({
            url: `/${Language.url}documents/basket/products`,
            data: {
                productId: $(e.currentTarget).attr('data-buy-product')
            },
            type: 'post',
            success() {
                formIsChange = true;
                autosave();
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });

    $body.on('click', '[data-unbuy-product]', {}, (event) => {
        const $this = $(event.currentTarget);

        if ($this.closest('.list__item').hasClass('element--half-faded') === false) {
            $this.closest('.list__item').addClass('element--half-faded');

            $.ajax({
                url: `${urlPrefix}documents/basket/products/${$this.attr('data-unbuy-product')}`,
                type: 'post',
                dataType: 'json',
                data: {
                    _method: 'DELETE'
                },
                success() {
                    formIsChange = true;
                    autosave();
                },
                error(request) {
                    if (request && 'responseJSON' in request) {
                        import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                            module.default(request.responseJSON);
                        });
                    }
                }
            });
        }
    });

    $body.on('change', '[data-product-qty]', {}, (event) => {
        if (window.ajaxProductQty != null) {
            window.ajaxProductQty.abort();
            window.ajaxProductQty = null;
        }

        const $this = $(event.currentTarget);

        window.ajaxProductQty = $.ajax({
            url: `${urlPrefix}documents/basket/products/${$this.attr('data-product-qty')}`,
            type: 'post',
            dataType: 'json',
            data: {
                _method: 'PUT',
                data: {
                    productQuantity: $this.val()
                }
            },
            success() {
                window.ajaxProductQty = null;
                formIsChange = true;
                autosave();
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });


    $body.on('click', '.js--submit-basket', {}, (event) => {
        $(event.currentTarget).addClass('is-loading');

        $.ajax({
            url: `${urlPrefix}documents/basket/submit`,
            type: 'post',
            dataType: 'json',
            success(data) {
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(data);
                });
                import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
                    module.ModalBox.close();
                });
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });


    $body.on('click', '[data-add-bonus-points]', {}, () => {
        if (window.ajaxBasketBonusPoints != null) {
            window.ajaxBasketBonusPoints.abort();
            window.ajaxBasketBonusPoints = null;
        }

        const $bonusPoints = $('[data-bonus-points]');

        if ($bonusPoints.val() === '' && parseFloat($bonusPoints.val()) > 0) {
            return;
        }

        window.ajaxBasketBonusPoints = $.ajax({
            url: `${urlPrefix}documents/basket`,
            type: 'post',
            dataType: 'json',
            data: {
                _method: 'PUT',
                basket: {
                    bonusPointsUsed: parseFloat($bonusPoints.val())
                }
            },
            success() {
                window.ajaxBasketBonusPoints = null;
                formIsChange = true;
                autosave();
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });

    $body.on('click', '[data-add-voucher]', {}, () => {
        if (window.ajaxBasketVoucher != null) {
            window.ajaxBasketVoucher.abort();
            window.ajaxBasketVoucher = null;
        }

        const voucher = $('[data-voucher]')
            .val()
            .trim();

        if (voucher === '') {
            return;
        }

        window.ajaxBasketVoucher = $.ajax({
            url: `${urlPrefix}documents/basket`,
            type: 'post',
            dataType: 'json',
            data: {
                _method: 'PUT',
                basket: {
                    voucher
                }
            },
            success() {
                window.ajaxBasketVoucher = null;
                formIsChange = true;
                autosave();
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });

    $body.on('click', '[data-remove-voucher]', {}, () => {
        if (window.ajaxBasketVoucher != null) {
            window.ajaxBasketVoucher.abort();
            window.ajaxBasketVoucher = null;
        }

        window.ajaxBasketVoucher = $.ajax({
            url: `${urlPrefix}documents/basket`,
            type: 'post',
            dataType: 'json',
            data: {
                _method: 'PUT',
                basket: {
                    voucher: ''
                }
            },
            success() {
                window.ajaxBasketVoucher = null;
                formIsChange = true;
                autosave();
            },
            error(request) {
                if (request && 'responseJSON' in request) {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(request.responseJSON);
                    });
                }
            }
        });
    });
}
