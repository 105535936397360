import $ from 'jquery';
import Lang from '../../../../utils/Language';
import '../../../../utils/Multiselect';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import SvgSpriteExporter from "../../../../utils/SvgSpriteExporter";

export default ($multiSelects) => {
    $multiSelects.addClass('multiple-initialized').each((index, element) => {
        const $this = $(element);

        const name = $this.attr('name');

        let showSearchEngine = true;
        if ($this.hasClass('js-multiselectWithoutSearch')) {
            showSearchEngine = false;
        }

        let includeSelectAllOption = false;

        if ($this.attr('data-multiselect-all-options')) {
            includeSelectAllOption = true;
        }

        let enableLazyLoad = false;

        if ($this.attr('data-multiselect-lazy-loading')) {
            enableLazyLoad = true;
        }

        $this.multiselect({
            enableHTML: true,
            enableClickableOptGroups: true,
            enableCaseInsensitiveFiltering: showSearchEngine,
            includeSelectAllOption,
            selectAllText: Lang.t('common.select_all'),
            disableIfEmpty: true,
            buttonWidth: '100%',
            enableFiltering: showSearchEngine,
            allSelectedText: Lang.t('common.all_options_selected'),
            enableLazyLoad,
            buttonContainer: '<div class="multiselect-native-select-container" />',
            filterPlaceholder: Lang.t('common.search'),
            templates: {
                liGroup: '<li class="multiselect-item multiselect-group multiselect-item--header"><label></label></li>',
                button: `<div class="multiselect-native-select-container-button multiselect dropdown-toggle" data-toggle="dropdown">
                            <span class="multiselect-selected-text"></span>${SvgSpriteExporter('icon_arrow')}
                        </div>`,
                ul: '<ul class="multiselect-native-select-container-options"></ul>',
                li: '<li class="multiselect-native-select-container-options-item"><a tabindex="0" class="link--inherit"><label></label></a></li>',
                filter:
                    `<li class="multiselect-native-select-container-options-search multiselect-item multiselect-filter">
                        ${SvgSpriteExporter('icon_search')}
                        <input class="multiselect-search js-no-label" type="text" />
                    </li>`,
                filterClearBtn: SvgSpriteExporter('icon_close')
            },
            onInitialized() {
                if (!enableLazyLoad) {
                    $this.attr('data-name', $this.attr('name'));
                }
            },
            onChange(option) {
                const dependencyOptions = $(option).attr(
                    'data-dependency-options'
                );
                const dependencyElements = $this.attr('data-dependency');

                const event = document.createEvent('Event');
                event.initEvent('change', false, true);

                if (dependencyElements) {
                    const dependencyFields = dependencyElements.split(",");

                    if (dependencyFields) {
                        for (let i = 0; i < dependencyFields.length; i+=1) {
                            const dependencyElement = dependencyFields[i];

                            if (dependencyOptions) {
                                let $dependencySelect = $(`[data-name="data[${dependencyElement}][]"]`);

                                if ($dependencySelect.length === 0) {
                                    $dependencySelect = $(`[name="data[${dependencyElement}][]"]`);
                                }

                                $dependencySelect.multiselect(
                                    'dataprovider',
                                    JSON.parse(dependencyOptions)
                                );
                                $dependencySelect.multiselect('select', ['1']);
                                $dependencySelect[0].dispatchEvent(event);
                            }
                        }
                    }
                }

                $this[0].dispatchEvent(event); // Fix for IE 11
            },
            checkboxName() {
                return name;
            },
            buttonText(options, select) {
                if (options.length === 0) {
                    if (select[0].getAttribute('data-empty-option')) {
                        return select[0].getAttribute('data-empty-option');
                    }

                    return Lang.t('common.select_option');
                }
                if (options.length > 3) {
                    return Lang.t('common.options', { Count: options.length });
                }
                const labels = [];
                options.each((optionIndex, optionElement) => {
                    const $option = $(optionElement);

                    if ($option.attr('label') !== undefined) {
                        labels.push($option.attr('label'));
                    } else {
                        labels.push($option.text());
                    }
                });
                return `${labels.join(', ')}`;
            }
        });

        const $multiselect = $this.next('.multiselect-native-select-container');

        if (enableLazyLoad) {
            $multiselect.on('show.bs.dropdown', () => {
                $this.attr('data-name', $this.attr('name'));
            });
        }

        element.addEventListener('reset', () => {
            $this.multiselect('rebuild', false);
        });

        element.addEventListener('refreshValue', () => {
            $this.multiselect("select", element.value);
        });

    });
}
