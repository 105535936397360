import $ from 'jquery';
import 'slick-carousel';
import SvgSpriteExporter from "../../utils/SvgSpriteExporter";

export default ($standardCarousel, $compareCarousel) => {
    if ($standardCarousel) {
        $standardCarousel.each((index, element) => {
            const childElements = element.childNodes.length;

            if (childElements === 0) {
                return;
            }

            if (childElements === 1) {
                $(element).addClass('slick-initialized');
                return;
            }

            const config = {
                pauseOnHover: true,
                pauseOnFocus: true,
                swipeToSlide: true,
                centerPadding: '0px',
                cssEase: 'ease-in-out',
                infinite: false,
                slidesToScroll: 1,
                rows: 0,
                centerMode: true,
                adaptiveHeight: true,
                arrows: false,
                fade: true,
                prevArrow:
                    `<span class="slider-arrow slider-arrow--left slider-arrow--big">
                        <span>
                            ${SvgSpriteExporter('icon_arrow')}
                        </span>
                    </span>`,
                nextArrow:
                    `<span class="slider-arrow slider-arrow--right slider-arrow--big">
                        <span>
                            ${SvgSpriteExporter('icon_arrow')}
                        </span>
                    </span>`,
                autoplay: false,
                autoplaySpeed: 4000,
                dots: false,
                dotsClass: 'slider-pagination slider-pagination--dots',
                responsive: [],
                zIndex: 1,
            };

            if (element.hasAttribute('data-carousel-animation')) {
                config.cssEase = element.getAttribute('data-carousel-animation');
            }

            if (element.hasAttribute('data-carousel-infinite')) {
                config.infinite = true;
                config.centerMode = false;
            }

            if (element.hasAttribute('data-carousel-arrows')) {
                config.arrows = true;
            }

            if (element.hasAttribute('data-carousel-dots')) {
                config.dots = true;
            }

            if (element.hasAttribute('data-carousel-autoplay')) {
                config.autoplay = true;
            }

            if (element.hasAttribute('data-carousel-vertical')) {
                config.vertical = true;
                config.verticalSwiping = true;
            }

            if (element.hasAttribute('data-carousel-autoplay-speed')) {
                config.autoplaySpeed = parseInt(element.getAttribute('data-carousel-autoplay-speed'), 10);
            }

            if (element.hasAttribute('data-slides-desktop')) {
                const slidesToShowDesktop = parseInt(element.getAttribute('data-slides-desktop'), 10);
                config.slidesToShow = childElements <= slidesToShowDesktop ? childElements : slidesToShowDesktop;
                config.variableWidth = false;
                config.centerMode = false;
                config.adaptiveHeight = false;
                config.fade = false;
            }

            if (element.hasAttribute('data-slides-tablet')) {
                const slidesToShowTablet = parseInt(element.getAttribute('data-slides-tablet'), 10);
                config.responsive.push({
                    breakpoint: 991,
                    settings: {
                        slidesToShow: childElements <= slidesToShowTablet ? childElements : slidesToShowTablet,
                        fade: false
                    }
                });
                config.variableWidth = false;
                config.centerMode = false;
                config.adaptiveHeight = false;
            }

            if (element.hasAttribute('data-slides-mobile')) {
                const slidesToShowMobile = parseInt(element.getAttribute('data-slides-mobile'), 10);
                config.responsive.push({
                    breakpoint: 768,
                    settings: {
                        slidesToShow: childElements <= slidesToShowMobile ? childElements : slidesToShowMobile,
                        dots: false,
                        arrows: false,
                        fade: false
                    }
                });
                config.variableWidth = false;
                config.centerMode = false;
                config.adaptiveHeight = false;
            } else {
                config.responsive.push({
                    breakpoint: 768
                });
            }

            const $slider = $(element);

            if (element.hasAttribute('data-carousel-navigation-id')) {
                const $navigation = $(`#${element.getAttribute('data-carousel-navigation-id')}:visible`);

                if ($navigation.length > 0) {
                    config.asNavFor = `#${element.getAttribute('data-carousel-navigation-id')}`;
                    config.focusOnSelect = true;
                }
            }

            $slider.slick(config);

            setTimeout(() => {
                $slider.slick('setPosition');
            }, 200);
        });
    }

    if ($compareCarousel) {
        $compareCarousel.each((index, element) => {
            const $this = $(element);
            const itemsCount = $this.find('.js-compare-product').length;

            $this.slick({
                swipeToSlide: true,
                infinite: false,
                cssEase: 'ease out',
                slidesToScroll: 1,
                rows: 0,
                slidesToShow: itemsCount <= 4 ? itemsCount : 4.5,
                slide: '.js-compare-product',
                centerPadding: '0px',
                prevArrow:
                    `<span class="button button--with-icon slider-arrow slider-arrow--left margin-right-1 slider-arrow--static">
                        ${SvgSpriteExporter('icon_arrow', 'svg-icon-arrow')}
                    </span>`,
                nextArrow:
                    `<span class="button button--with-icon slider-arrow slider-arrow--right margin-left-1 slider-arrow--static">
                        ${SvgSpriteExporter('icon_arrow', 'svg-icon-arrow')}
                    </span>`,
                appendArrows: `#${$this.attr('data-pagination')}`,
                responsive: [{
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2.5
                    }
                }]
            });
        });
    }
}
