import $ from "jquery";
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import lozad from 'lozad';
import WOW from 'wowjs';
import ElementQueries from 'css-element-queries/src/ElementQueries';
import InitializerEventDispatcher from "../../utils/InitializerEvent";
import { initSidebar, showSidebarOnDesktop } from "./components/Sidebar";
import initSearch from "./components/Search";
import initDocuments from "../Document";
import initHeader from "./components/Header";
import initForm from "../Form";
import initCarousel from "../Carousel";
import SvgSpriteExporter from "../../utils/SvgSpriteExporter";

export default () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    let wow = new WOW.WOW({
        live: false
    });
    wow.init();
    ElementQueries.init();

    initHeader();

    if ('dsOnPageLoadFormHandlerResponse' in window) {
        import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
            module.default(window.dsOnPageLoadFormHandlerResponse);
        });
    }

    initSidebar();
    initSearch();

    const countDownSelectors = document.getElementsByClassName('js-initializing-countdown-timer');

    if (countDownSelectors.length > 0) {
        import(/* webpackChunkName: "countdown" */ '../Countdown').then(module => {
            module.default(countDownSelectors);
        });
    }

    initDocuments();

    import(/* webpackChunkName: "modal-box", webpackPreload: true */ '../ModalBox').then(module => {
        module.initModalBox();

        if ('dsModalBoxHolderStyle' in window) {
            module.ModalBox.config.holderStyle = window.dsModalBoxHolderStyle;
        }

        const visibleModalBox = module.ModalBox.getPopupFromDOM();

        if (!visibleModalBox) {
            let styleHolder = null;
            if ('dsPageDefaultModalBox' in window) {
                if ('dsPageDefaultModalBoxHolderStyle' in window) {
                    styleHolder = window.dsPageDefaultModalBoxHolderStyle
                }

                module.ModalBox.open(window.dsPageDefaultModalBox, null, null, styleHolder);
            }

            if ('dsPageLoadModalBox' in window) {
                if ('dsPageLoadModalBoxHolderStyle' in window) {
                    styleHolder = window.dsPageLoadModalBoxHolderStyle
                }

                module.ModalBox.open(window.dsPageLoadModalBox, null, null, styleHolder);
            }
        }
    });

    const dropDownTriggers = document.getElementsByClassName('js-drop-down-trigger');

    if (dropDownTriggers.length > 0) {
        import(/* webpackChunkName: "dropdown-menu" */ '../../utils/DropDownMenu').then(module => {
            module.default(dropDownTriggers);
        });
    }


    const filtersSidebar = document.querySelectorAll('[data-sidebar="filters"]');
    const navigationSidebar = document.querySelectorAll('[data-sidebar="main"]');

    if (filtersSidebar.length > 0 || navigationSidebar.length > 0) {
        showSidebarOnDesktop(filtersSidebar, navigationSidebar);
    }

    InitializerEventDispatcher.addEventListener('ContentChanged', () => {
        const lazyImages = [].slice.call(document.querySelectorAll('[loading="lazy"]'));

        const lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const lazyImage = entry.target;
                    if (lazyImage.dataset.background) {
                        lazyImage.style.backgroundImage = lazyImage.dataset.background;
                    }

                    if (lazyImage.dataset.poster) {
                        lazyImage.poster = lazyImage.dataset.poster;
                    }

                    lazyImage.removeAttribute('data-background');
                    lazyImage.removeAttribute('width');
                    lazyImage.removeAttribute('height');
                    lazyImage.removeAttribute('loading');
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach((lazyImage) => {
            lazyImageObserver.observe(lazyImage);
        });

        const $standardCarousel = $('.js-slider:visible:not(.slick-initialized)');
        const $compareCarousel = $('.js-compare-products-slider:visible:not(.slick-initialized)');

        if ($standardCarousel.length > 0 || $compareCarousel.length > 0) {
            initCarousel($standardCarousel, $compareCarousel);
        }

        const accordions = document.getElementsByClassName('js-accordion');

        if (accordions.length > 0) {
            import(/* webpackChunkName: "accordion" */ '../Accordion').then(module => {
                module.default(accordions);
            });
        }

        const tooltips = document.querySelectorAll('[title]');

        if (tooltips.length > 0) {
            import(/* webpackChunkName: "tooltip", webpackPreload: true */ '../Tooltip').then(module => {
                module.default(tooltips);
            });
        }

        import(/* webpackChunkName: "mega-menu", webpackPreload: true */ '../MegaMenu').then(module => {
            module.default();
        });

        const invoiceDataSelectors = document.getElementsByClassName('initializer--invoice-country-select');

        if (invoiceDataSelectors.length > 0) {
            import(/* webpackChunkName: "invoice-data" */ '../../utils/InvoiceData').then(module => {
                module.default(invoiceDataSelectors);
            });
        }

        import(/* webpackChunkName: "tab", webpackPreload: true */ '../Tab').then(module => {
            module.default();
        });

        import(/* webpackChunkName: "change-password", webpackPreload: true */ './components/ChangePassword').then(module => {
            module.default();
        });

        import(/* webpackChunkName: "confirm-action", webpackPreload: true */ './components/ConfirmAction').then(module => {
            module.default();
        });

        const forms = document.getElementsByTagName('form');

        if (forms.length > 0) {
            initForm(forms);
        }
    }, 100);

    InitializerEventDispatcher.addEventListener('ContentChanged', () => {
        ElementQueries.init();
    }, 500);

    window.SuggestionProductList = (data) => {
        let img = null;

        if ('photo_a' in data && 'photo_a_2x' in data) {
            img = document.createElement('IMG');
            img.setAttribute(
                'src',
                'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
            );
            img.setAttribute('alt', data.name);
            img.setAttribute('sizes', '50px');
            img.setAttribute('srcset', `${data.photo_a} 50w, ${data.photo_a_2x} 100w`);
        }

        const div = document.createElement('DIV');

        div.classList.add('padding-vcenter-1');
        div.classList.add('element--flex-0');
        div.appendChild(document.createTextNode(data.name));

        const item = document.createElement('DIV');

        item.classList.add('element--flex');
        item.classList.add('element--flex-row-wrap');
        item.classList.add('element--flex-items--center');

        if (img) {
            item.appendChild(img);
        }

        item.appendChild(div);

        return item;
    };

    window.SuggestionProductListSortable = (data) => {
        let img = null;

        if ('photo_a' in data && 'photo_a_2x' in data) {
            img = document.createElement('IMG');
            img.setAttribute(
                'src',
                'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
            );
            img.setAttribute('alt', data.name);
            img.setAttribute('sizes', '50px');
            img.setAttribute('srcset', `${data.photo_a} 50w, ${data.photo_a_2x} 100w`);
        }

        const div = document.createElement('DIV');

        div.classList.add('padding-vcenter-1');
        div.classList.add('element--flex-0');
        div.appendChild(document.createTextNode(data.name));

        const item = document.createElement('DIV');

        item.classList.add('element--flex');
        item.classList.add('element--flex-row-wrap');
        item.classList.add('element--flex-items--center');

        const dragHandle = document.createElement('div');
        dragHandle.innerHTML = `<span class="drag-handle margin-right-1 element--flex element--flex-items--center ">${SvgSpriteExporter('icon_dragdrop')}</span>`;
        item.appendChild(dragHandle.firstChild);

        if (img) {
            item.appendChild(img);
        }

        item.appendChild(div);

        return item;
    };

    window.addEventListener('resize', () => {
        // We execute the same script as before
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    document.addEventListener("AnimationChanged", () => {
        wow = new WOW.WOW({
            live: false
        });
        wow.init();
    });

    document.addEventListener("AccordionChanged", () => {
        const accordions = document.getElementsByClassName('js-accordion');

        if (accordions.length > 0) {
            import(/* webpackChunkName: "accordion" */ '../Accordion').then(module => {
                module.default(accordions);
            });
        }
    });

    document.addEventListener("CarouselChanged", () => {
        const $standardCarousel = $('.js-slider:visible:not(.slick-initialized)');

        if ($standardCarousel.length > 0) {
            initCarousel($standardCarousel);
        }
    });

    document.addEventListener("StyleChanged", () => {
        ElementQueries.init();
    });

    InitializerEventDispatcher.dispatchEvent('ContentChanged');
}