class InitEventDispatcher {
    constructor()
    {
        this.events = {
            ContentLoaded: [],
            ContentChanged: []
        };
    }

    dispatchEvent(event)
    {
        if (event in this.events === false) {
            return;
        }

        let i;

        this.events[event].sort((a, b) => a.priority - b.priority);

        for (i = 0; i < this.events[event].length; i+=1) {
            this.events[event][i].executable.call(event);
        }
    }

    addEventListener(eventName, executable, priority)
    {
        if (!(eventName in this.events)) {
            throw new Error(`Invalid event name: ${eventName}`);
        }

        this.events[eventName].push({eventName, executable, priority});
    }
}

const InitializerEventDispatcher = new InitEventDispatcher();

export default InitializerEventDispatcher;
