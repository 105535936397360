import $ from 'jquery';
import initButtons from './components/Buttons';
import initInputs from './components/Inputs';
import initSpinner from './components/Spinner';
import initMultiSelect from './components/MultiSelect';
import initSliders from './components/Slider';
import FormHandler from '../../utils/FormHandler';

export default (forms) => {

    const changeEvent = document.createEvent('Event');
    changeEvent.initEvent('change', false, true);

    for (let f=0; f < forms.length; f+=1) {
        const form = forms[f];

        if (form.classList.contains('js-form-handler')) {
            if ('dropzone' in form === false) {
                form.dropzone = [];
            }

            const dropZones = form.getElementsByClassName('js-drop-zone');

            if (dropZones.length > 0) {
                import(/* webpackChunkName: "drop-zone" */ './components/Dropzone').then(module => {

                    const DropZone = module.default;

                    for (let d = 0; d < dropZones.length; d+=1) {
                        if ('dropzone' in dropZones[d] === false) {
                            const dropZone = new DropZone(dropZones[d]);
                            dropZones[d].dropzone = dropZone;
                            dropZones[d].classList.add('drop-zone-initialized');
                            form.dropzone.push(dropZone);

                            dropZones[d].dispatchEvent(changeEvent);
                        }
                    }
                });
            }

            if ('suggestionUI' in form === false) {
                form.suggestionUI = [];
            }

            const suggestionUIElements = form.getElementsByClassName('js-suggestion-ui');

            if (suggestionUIElements.length > 0) {
                import(/* webpackChunkName: "suggestion-ui" */ './components/SuggestionUI').then(module => {
                    const SuggestionUI = module.default;

                    for (let s = 0; s < suggestionUIElements.length; s += 1) {
                        if ('suggestionUI' in suggestionUIElements[s] === false) {
                            const suggestionUI = new SuggestionUI(suggestionUIElements[s], form);
                            suggestionUIElements[s].suggestionUI = suggestionUI;
                            suggestionUIElements[s].classList.add('suggestion-ui-initialized');
                            form.suggestionUI.push(suggestionUI);

                            suggestionUIElements[s].dispatchEvent(changeEvent);
                        }
                    }
                });
            }

            if ('FormHandler' in form === false) {
                form.FormHandler = new FormHandler(form);
            } else {
                form.FormHandler.initSubmitters();
                form.FormHandler.initLiveEventListeners();
            }

            const fields = form.querySelectorAll('[data-validate]');

            if (fields.length > 0) {
                import(/* webpackChunkName: "form-validation" */ '../../utils/FormValidation').then(formValidationModule => {
                    const FormValidation = formValidationModule.default;
                    form.Validation = new FormValidation(form);
                })
            }
        }

        if (!('submittingAllowed' in form)) {
            const inputs = form.getElementsByTagName('input');
            let hasBotProtectionField = false;

            for (let j = 0; j < inputs.length; j+=1) {
                const k = inputs[j].getAttribute('name');

                if (k && k.match(/dsbpi/i)) {
                    hasBotProtectionField = true;
                    inputs[j].parentNode.removeChild(inputs[j]);

                    form.submittingAllowed = true;
                    break;
                }
            }

            if (!hasBotProtectionField) {
                form.submittingAllowed = true;
            }
        }
    }


    const tokenInputs = document.getElementsByClassName('js-token-input');

    if (tokenInputs.length > 0) {
        import(/* webpackChunkName: "token-input" */ './components/TokenInput').then(module => {
            const TokenInput = module.default;

            for (let t = 0; t < tokenInputs.length; t+=1) {
                if ('tokenInput' in tokenInputs[t] === false) {
                    tokenInputs[t].tokenInput = new TokenInput(tokenInputs[t]);
                    tokenInputs[t].dispatchEvent(changeEvent);

                    tokenInputs[t].tokenInput.input.addEventListener('change', () => {
                        tokenInputs[t].setAttribute('value', JSON.stringify(tokenInputs[t].tokenInput.TokenInput.getTokens()));
                        tokenInputs[t].dispatchEvent(changeEvent);
                    });
                }
            }
        });
    }



    const $multiselects = $('.js-multiselect:not(.multiple-initialized)');

    if ($multiselects.length > 0) {
        initMultiSelect($multiselects);
    }


    const $richTextEditors = $('.js-rich-text-editor:not(.js-rte-init)');

    if ($richTextEditors.length > 0) {
        import(/* webpackChunkName: "rich-text-editor" */ './components/RichTextEditor').then(module => {
            module.default($richTextEditors);
        });
    }


    const masterCheckboxes = document.getElementsByClassName('initializer--master-checkbox');

    if (masterCheckboxes.length > 0) {
        import(/* webpackChunkName: "master-checkbox" */ './components/MasterCheckbox').then(module => {
            module.default(masterCheckboxes);
        });
    }


    const datapickers = document.getElementsByClassName('initializer--date-picker');

    if (datapickers.length > 0) {
        import(/* webpackChunkName: "date-picker" */ './components/DatePicker').then(module => {
            const Datepicker = module.default;

            for (let i = 0; i < datapickers.length; i+=1) {
                const element = datapickers.item(i);

                if ('Datepicker' in element === false) {
                    element.Datepicker = new Datepicker(element);
                }
            }
        });
    }


    const sliders = document.getElementsByClassName('js-slider-range');

    if (sliders.length > 0) {
        initSliders(sliders);
    }


    const colorPickers = document.getElementsByClassName('js-color-picker');

    if (colorPickers.length > 0) {
        import(/* webpackChunkName: "color-picker" */ './components/ColorPicker').then(module => {
            module.initColorPicker(colorPickers);
        });
    }


    const codeEditors = document.getElementsByClassName('js-code-editor');

    if (codeEditors.length > 0) {
        import(/* webpackChunkName: "code-editor" */ './components/CodeEditor').then(module => {
            module.default(codeEditors);
        });
    }

    initInputs();
    initButtons();
    initSpinner();
}
