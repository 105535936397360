import $ from 'jquery';

export default () => {
    const $body = $('body');

    $body
        .off('click', '.do-increase-spinner')
        .on('click', '.do-increase-spinner', e => {
            const $this = $(e.target);
            const spinnerControl = $this.data('spinner-control');
            const $spinnerInput = $(`[data-spinner-value="${spinnerControl}"]`);
            let spinnerValue = $spinnerInput.val();

            if (spinnerValue === '') {
                spinnerValue = 0;
            }

            $spinnerInput.val(parseInt(spinnerValue, 10) + 1);
            $spinnerInput.change();
        });

    $body
        .off('click', '.do-decrease-spinner')
        .on('click', '.do-decrease-spinner', e => {

            const $this = $(e.target);
            const spinnerControl = $this.data('spinner-control');
            const $spinnerInput = $(`[data-spinner-value="${spinnerControl}"]`);
            const spinnerValue = $spinnerInput.val();
            const min = 1;

            if (spinnerValue > min) {
                $spinnerInput.val(parseInt(spinnerValue, 10) - 1);
                $spinnerInput.change();
            }
        });
}