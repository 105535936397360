import $ from 'jquery';
import InitializerEventDispatcher from '../../../../utils/InitializerEvent';
import Language from "../../../../utils/Language";

export default () => {
    const $body = $('body');
    const $searchForm = $('#js-search-form');
    const $searchInput = $('#js-search-input');
    let suggestionAjax = null;
    const $suggestionList = $('#js-search-suggestions');
    const $cleanButton = $('#js-clean-search');
    const $loader = $('#js-search-loading');
    const $suggestionListHolder = $suggestionList.parent();

    $body.on('click', '.js-trigger-search', () => {
        const overlay = document.getElementById('search');
        const state = overlay ? overlay.classList.contains('is-active') : false;

        document.body.classList.toggle('no-scrolling', !state);
        if (overlay) {
            overlay.classList.toggle('is-active', !state);
        }

        if (!state) {
            // cannot focus hidden elements
            setTimeout(() => {
                $searchInput.focus();
                if ($searchInput.val()) {
                    $cleanButton.addClass('is-active');
                }
            }, 400);
        }
    });

    $body.on('click', '#search', (e) => {
        if ($(e.target).is('#js-search-form, #js-search-form *')) {
            return;
        }

        document.body.classList.toggle('no-scrolling', false);
        e.currentTarget.classList.toggle('is-active', false);
    });

    $body.on('click', '#js-clean-search', () => {
        $searchForm.find('input[type="search"]').val('');
        $suggestionList.parent().removeClass('is-active');
        $suggestionList.empty().removeAttr('style');
        $cleanButton.removeClass('is-active');
        $loader.hide();

        if (suggestionAjax != null) {
            suggestionAjax.abort();
            suggestionAjax = null;
        }
    });

    $searchInput.on('input', () => {
        const formData = $searchForm.serializeArray();

        if (formData[0].value.length > 2) {
            $cleanButton.addClass('is-active');
        } else if (formData[0].value.length === 0) {
            // If empty input
            $searchForm.find('input[type="search"]').val('');
            $suggestionList.parent().removeClass('is-active');
            $suggestionList.empty().removeAttr('style');
            $cleanButton.removeClass('is-active');
            $loader.hide();

            return;
        } else {
            // Between 1 and 2 symbols
            return;
        }

        $suggestionList.css({ 'min-height': '150px' });
        $suggestionList.addClass('element--half-faded');
        $suggestionListHolder.addClass('is-active');

        $loader.removeAttr('style');

        if (suggestionAjax != null) {
            suggestionAjax.abort();
            suggestionAjax = null;
        }

        if ('timeout' in $searchInput) {
            clearTimeout($searchInput.timeout);
        }

        $searchInput.timeout = setTimeout(() => {
            suggestionAjax = $.ajax({
                url: `/${Language.url}products/suggest/`,
                type: 'post',
                data: {
                    s: formData[0].value,
                    c: formData[1] ? formData[1].value : ''
                },
                success(data) {
                    $suggestionList.html(data);
                    InitializerEventDispatcher.dispatchEvent('ContentChanged');

                    $loader.hide();
                    $suggestionList.removeClass('element--half-faded');
                    suggestionAjax = null;
                }
            });
        }, 1000);



    });

    $searchForm.submit((e) => {
        const formData = $searchForm.serializeArray();

        if (!formData[0]
            || formData[0].value.length < 3
            || $suggestionList.find('.no-results').length === 1
        ) {
            $(e.currentTarget)
                .find('button')
                .removeClass('is-loading');

            return false;
        }

        return true;
    });
}
