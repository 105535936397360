import $ from 'jquery';

export default () => {
    const $body = $('body');

    $body.on('click', '.button--is-disabled', e => {
        e.preventDefault();
        return false;
    });

    $(window).scroll(() => {
        const button = document.getElementById('go-to-top');

        if (!button) {
            return;
        }

        if ($(document).scrollTop() > 300) {
            button.classList.add('is-visible');
        } else {
            button.classList.remove('is-visible');
        }
    });

    const button = document.getElementById('go-to-top');

    if (button) {
        button.addEventListener('click', () => {
            window.scrollTo(0, 0);
        });
    }
}