if (!window.dsSvgSprite) {
    window.dsSvgSprite = '';
}

export default (name, classes, title) => {
    let classAttr = '';

    if (classes && classes !== '') {
        classAttr = `class="${classes}"`;
    }

    let titleAttr = '';

    if (title && title !== '') {
        titleAttr = `title="${title}"`;
    }

    return `<svg ${classAttr} ${titleAttr}>
                <use href="${window.dsSvgSprite}#${name}"></use>
            </svg>`;
}