import $ from 'jquery';

let lastScrollTop = 0;

function positionScrollHeader(
    element,
    scrollPosition,
    documentHeight,
    scrollHeaderHeightHolder,
    scrollSpyNav
) {

    if (!element) {
        return;
    }

    const elementHeight = element ? element.offsetHeight : 0;

    const scrollSpyNavigation = scrollSpyNav;

    // When user didn't scroll enough
    if (scrollPosition < elementHeight) {
        element.classList.remove('is-hidden');
        element.classList.remove('is-fixed');
        element.classList.add('is-active');

        scrollHeaderHeightHolder.removeAttribute('style');

        if (scrollSpyNavigation) {
            scrollSpyNavigation.removeAttribute('style');
            scrollSpyNavigation.classList.remove('is-fixed');
        }

        lastScrollTop = 0;

        return;
    }

    // Make sure they scroll more than 5
    if (Math.abs(lastScrollTop - scrollPosition) <= 5) {
        return;
    }

    if (scrollPosition > lastScrollTop && scrollPosition > elementHeight) {
        element.classList.add('is-hidden');
        // Prevent show and hide the header.
        setTimeout(() => {
            element.classList.add('is-fixed');
            scrollHeaderHeightHolder.setAttribute('style', `padding-top: ${elementHeight}px`);
        }, 300);
        element.classList.remove('is-active');

        if (scrollSpyNavigation) {
            scrollSpyNavigation.removeAttribute('style');
            scrollSpyNavigation.classList.remove('is-fixed');
        }

        const megaMenuTrigger = document.querySelector('.js-mega-menu-trigger.is-active');

        if (megaMenuTrigger) {
            megaMenuTrigger.click();
        }
    } else if (scrollPosition < lastScrollTop && (scrollPosition + window.innerHeight) < documentHeight) {
        element.classList.remove('is-hidden');
        element.classList.add('is-fixed');
        element.classList.add('is-active');

        scrollHeaderHeightHolder.setAttribute('style', `padding-top: ${elementHeight}px`);

        if (scrollSpyNavigation) {
            scrollSpyNavigation.style.top = `${elementHeight}px`;
        }
    }

    lastScrollTop = scrollPosition;
}

let lastScrollTopPositionForSpyNavigation = 0;

function positionScrollSpyNavigation(
    element,
    elementOffsetTop,
    scrollPosition,
    scrollSpyNavigationHolder
) {
    if (!element || !elementOffsetTop) {
        return;
    }


    const elementHeight = element ? element.offsetHeight : 0;

    // When user didn't scroll enough
    if (scrollPosition < elementOffsetTop + elementHeight) {
        element.classList.remove('is-fixed');
        element.removeAttribute('style');
        scrollSpyNavigationHolder.removeAttribute('style');

        lastScrollTopPositionForSpyNavigation = 0;

        return;
    }

    // Make sure they scroll more than 5
    if (Math.abs(lastScrollTopPositionForSpyNavigation - scrollPosition) <= 5)
        return;

    scrollSpyNavigationHolder.setAttribute('style', `height: ${elementHeight}px`);
    element.classList.add('is-fixed');

    lastScrollTopPositionForSpyNavigation = scrollPosition;
}

export default () => {
    const { body } = document;
    const html = document.documentElement;
    let documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    );

    const scrollHeader = document.getElementById('js-main-header');
    const scrollHeaderHeightHolder = document.getElementById('js-main-content');
    const scrollSpyNavigationHolder = document.getElementById('js-page-navigation-fixed');
    const scrollSpyNavigation = scrollSpyNavigationHolder
        ? scrollSpyNavigationHolder.firstElementChild
        : null;

    const scrollSpyNavigationOffsetTop = scrollSpyNavigation
        ? scrollSpyNavigation.getBoundingClientRect().top
        : null;

    if (document.documentElement.scrollTop > 0) {
        positionScrollHeader(
            scrollHeader,
            document.documentElement.scrollTop,
            documentHeight,
            scrollHeaderHeightHolder,
            scrollSpyNavigation
        );
    }

    $(window).scroll(() => {
        const scrollPosition = $(document).scrollTop();

        positionScrollHeader(
            scrollHeader,
            scrollPosition,
            documentHeight,
            scrollHeaderHeightHolder,
            scrollSpyNavigation
        );

        positionScrollSpyNavigation(
            scrollSpyNavigation,
            scrollSpyNavigationOffsetTop,
            scrollPosition,
            scrollSpyNavigationHolder
        );
    });

    $(window).resize(() => {
        documentHeight = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
    });

    /**
     * ScrollSpyNavigation
     */
    if (scrollSpyNavigation) {
        const $body = $('body');

        import(/* webpackChunkName: "bootstrap-scrollspy" */ 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy').then(() => {
            $body.scrollspy({
                target: '#js-page-navigation-fixed',
                offset: scrollHeader.offsetHeight + scrollHeader.offsetHeight
            });

            document.addEventListener("LoadedImage", () => {
                $body.scrollspy('refresh');
            });
        });

        $body.on('click', '#js-page-navigation-fixed a', (e) => {
            e.preventDefault();

            const sectionId = $(e.currentTarget).attr('href');
            $('html').animate({
                scrollTop: $(sectionId).offset().top - (scrollHeader ? scrollHeader.offsetHeight : 0) + (scrollSpyNavigationHolder ? scrollSpyNavigationHolder.offsetHeight : 0)
            }, 1000);
        });
    }
}
