function createLabel() {
    if (this.value === '' && this.labelInitialized) {
        const fieldLabels = this.nextElementSibling;

        if (fieldLabels) {
            fieldLabels.parentNode.removeChild(fieldLabels);
            delete this.labelInitialized;
            this.classList.remove('with-label');
        }
    } else if (this.value !== '' && !this.labelInitialized) {
        const label = document.createElement('label');
        label.appendChild(
            document.createTextNode(this.getAttribute('placeholder'))
        );
        this.parentNode.insertBefore(label, this.nextElementSibling);
        this.labelInitialized = true;
        this.classList.add('with-label');
    }
}

export default () => {
    const inputs = document.querySelectorAll('[placeholder]');

    if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i += 1) {
            const element = inputs[i];

            if ('labelEventInit' in element === false
                && element.classList.contains('js-no-label') === false
            ) {
                createLabel.call(element);
                element.addEventListener('input', createLabel);
                element.addEventListener('reset', createLabel);
                element.labelEventInit = true;
            }

            if ('trimValueEventInit' in element === false
                && element.hasAttribute('data-validate') === false
            ) {
                element.addEventListener('change', (e) => {
                    e.target.value = e.target.value.trim();
                    element.trimValueEventInit = true;
                });
            }
        }
    }

    const checkboxesAsRadio = document.querySelectorAll('.js--checkboxes-as-radio');

    if (checkboxesAsRadio.length > 0) {
        Array.prototype.forEach.call(checkboxesAsRadio, (button) => {
            button.addEventListener('click', (e) => {
                const isChecked = e.target.checked;

                const groupName = e.target.getAttribute('name');
                const checksInGroup = document.querySelectorAll(`[name="${groupName}"]`);

                Array.prototype.forEach.call(checksInGroup, (checkBox) => {
                    checkBox.checked = false;
                });

                e.target.checked = !!isChecked;
            });
        });

    }

}
