import { confirmBox } from '../components/AlertBox';
import Lang from './Language';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * Handles form submission via ajax request. Only post forms are supported.
 * All requests must return \system\models\FormHandlerResponse
 */
const getSubmitterCustomConfirmationMessage = (submitter) => {
    if (!submitter.getAttribute('data-action-selector')) {
        return null;
    }

    let actionSelector = document.getElementById(
        submitter.getAttribute('data-action-selector')
    );

    if (!actionSelector) {
        return null;
    }

    if (actionSelector.tagName === 'SELECT') {
        actionSelector = actionSelector
            .getElementsByTagName('option')
            .item(actionSelector.selectedIndex);
    }

    return actionSelector.getAttribute('data-custom-confirm-message');
};

export default class {

    constructor(form)
    {
        this.form = form;
        this.submitter = null;
        this.savedFiles = {};

        this.initSubmitters();
        this.initLiveEventListeners();
        this.initEventListeners();
    }

    initLiveEventListeners()
    {
        if (this.form.getAttribute('data-live-form')) {

            this.setLiveFormEventListener(this.form.querySelectorAll('input:not(.js-do-not-include-in-live-form)'));
            this.setLiveFormEventListener(this.form.querySelectorAll('textarea:not(.js-do-not-include-in-live-form)'));
            this.setLiveFormEventListener(this.form.querySelectorAll('select:not(.js-do-not-include-in-live-form)'));

            if ('dropzone' in this.form) {
                this.setLiveFormEventListener(this.form.dropzone);
            }

            if ('suggestionUI' in this.form) {
                this.setLiveFormEventListener(this.form.suggestionUI);
            }
        }
    }

    setLiveFormEventListener(elements)
    {
        for (let i = 0; i < elements.length; i+=1) {
            const element = elements[i];

            if (!('fh_initialized' in elements[i])) {
                if (element instanceof HTMLElement) {
                    element.fh_initialized = 1;

                    element.addEventListener('change', () => {
                        import(/* webpackChunkName: "modal-box" */ '../components/ModalBox').then(module => {
                            if (module.ModalBox.loadingEl) {
                                module.ModalBox.loadingEl.removeAttribute('style');
                            }
                        });

                        this.process('POST', {
                            saveFilesLocally: true,
                            headers: { 'X-Live-Form': 1 }
                        })
                    })
                }

            }
        }
    }

    initSubmitters()
    {
        this.submitters = this.form.querySelectorAll('[type="submit"]');

        for (let i = 0; i < this.submitters.length; i+=1) {
            const s = this.submitters.item(i);

            if ('fh_initialized' in s === false) {
                s.fh_initialized = 1;

                s.addEventListener('click', () => {
                    this.submitter = s;
                    s.classList.add('_submitter');
                    s.classList.add('is-loading');

                });
            }
        }
    }

    initEventListeners()
    {
        this.form.addEventListener('submit', (evt) => {
            // Only post forms are supported
            if (evt.target.method !== 'post') {
                return;
            }

            evt.stopPropagation();
            evt.preventDefault();

            if (evt.target.getAttribute('data-confirm-submit')) {
                const submitter = evt.target.getElementsByClassName('_submitter');
                let message = null;

                if (submitter.length > 0) {
                    message = getSubmitterCustomConfirmationMessage(submitter[0]);
                }

                /**
                 * CancelCallback
                 * When use bulk action we submit FORM and if cancel should remove button's loading etc.
                 */
                confirmBox(
                    message || Lang.t('common.confirm_to_continue'),
                    () => this.process(),
                    () => this.form.Validation.enable()
                );

                return;
            }

            this.process();
        });

        this.form.addEventListener('reset', () => {
            const resetEvent = document.createEvent('Event');
            resetEvent.initEvent('reset', false, true);

            for (let i = 0; i < this.submitters.length; i+=1) {
                const submitter = this.submitters.item(i);
                submitter.classList.remove('is-loading');
            }

            for (let e = 0; e < this.form.elements.length; e+=1) {
                const element = this.form.elements.item(e);

                if (element.type) {
                    switch (element.type.toLowerCase()) {
                        case "text":
                        case "password":
                        case "textarea":
                        case "hidden":
                            element.value = "";
                            break;
                        case "radio":
                        case "checkbox":
                            if (element.checked) {
                                element.checked = false;
                            }
                            break;
                        case "select-one":
                        case "select-multi":
                            element.selectedIndex = -1;
                            break;
                        default:
                            break;
                    }

                    element.dispatchEvent(resetEvent);
                }
            }
        });
    }

    process(methodForm, opt, callback)
    {
        const options = opt || {};

        if ('isDisable' in this || 'submittingAllowed' in this.form === false) {
            return;
        }

        this.isDisable = 1;

        if ('Validation' in this.form) {
            this.form.Validation.disable();
        }

        const xhr = new XMLHttpRequest();

        let action = this.form.getAttribute('action');

        if (action === '') {
            action = window.location.href;
        } else if (action.indexOf('://') === -1 && action.slice(0, 1) !== '/') {
            action = `/${Lang.url}${action}`;
        }

        xhr.open(!methodForm ? this.form.method : methodForm, action, true);
        xhr.withCredentials = false;

        xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText);

            if ('Validation' in this.form) {
                this.form.Validation.enable();
            }

            delete this.isDisable;

            import(/* webpackChunkName: "request-response" */ './RequestResponse').then(module => {
                module.default(resp);
            });

            if (xhr.status === 200
                && options
                && 'headers' in options === false
                && this.form.hasAttribute('data-reset-form')
            ) {
                this.form.reset();
            } else {
                for (let i = 0; i < this.submitters.length; i+=1) {
                    const submitter = this.submitters.item(i);
                    submitter.classList.remove('is-loading');
                }
            }

            let scrollerPosition = 0;
            if (this.form.hasAttribute('data-scroll-to-top') === false) {
                const doc = document.documentElement;
                scrollerPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            }

            if (xhr.status === 200 && this.form.hasAttribute('data-refresh-page-content')) {
                const pageXHR = new XMLHttpRequest();

                pageXHR.open('GET', window.location.href);
                pageXHR.onreadystatechange = () => {
                    if (pageXHR.readyState === 4 && pageXHR.status === 200) {
                        import(/* webpackChunkName: "request-response" */ './RequestResponse').then(module => {
                            module.default({
                                type: 'block_replace',
                                data: {
                                    blocks: [
                                        'page-content'
                                    ],
                                    context: pageXHR.responseText,
                                    callback: () => {
                                        /**
                                         * Need this timeout because HTML element replacement.
                                         */
                                        setTimeout(() => {
                                            window.scrollTo(0, scrollerPosition);
                                        }, 400);
                                    }
                                }
                            });
                        });
                    }
                };

                pageXHR.send();
            }


            if (typeof callback === 'function') {
                callback();
            }

            import(/* webpackChunkName: "modal-box" */ '../components/ModalBox').then(module => {
                if (module.ModalBox.loadingEl) {
                    module.ModalBox.loadingEl.style.display = 'none';
                }
            });
        };

        xhr.onerror = () => {
            import(/* webpackChunkName: "request-response" */ './RequestResponse').then(module => {
                module.default(JSON.parse(xhr.responseText));
            });
        };

        const headers = {
            Accept: 'application/json',
            'Cache-Control': 'no-cache',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Ajax-Request': 1,
            'X-Form-Handler-Request': 1
        };

        let headerName;
        let headerValue;

        for (headerName in headers) {
            headerValue = headers[headerName];
            xhr.setRequestHeader(headerName, headerValue);
        }

        if ('headers' in options) {
            for (headerName in options.headers) {
                headerValue = options.headers[headerName];
                xhr.setRequestHeader(headerName, headerValue);
            }
        }

        xhr.send(this.fetchFormData(options));
    }

    fetchFormData(options)
    {
        const formData = new FormData();

        if (this.submitter) {
            formData.append('_submitter', this.submitter.getAttribute('name'));
            formData.append('_submitterValue', this.submitter.getAttribute('value'));
        }

        let inputValues = '_fh_inputs=1&';

        for (let i = 0; i < this.form.elements.length; i+=1) {
            const input = this.form.elements.item(i);

            if (input.tagName !== 'BUTTON') {
                const inputName = input.getAttribute('name');
                const inputType = input.getAttribute('type');

                if (input.type === 'select-multiple') {
                    const optionsField = input.options;

                    for (let j = 0; j < optionsField.length; j+=1) {
                        if (optionsField.selected) {
                            formData.append(inputName, optionsField.value);
                        }
                    }
                } else if (
                    inputType !== 'file'
                    && (
                        !inputType
                        || input.checked
                        || (inputType !== 'checkbox' && inputType !== 'radio')
                    )
                    && input.tagName !== "FIELDSET"
                ) {
                    inputValues += `${inputName}=${encodeURIComponent(input.value.trim()).replace(/[!'()*]/g, escape)}&`;
                }
            }
        }

        formData.append('_fh_input_data', inputValues);

        if ('dropzone' in this.form) {
            for (let i = 0; i < this.form.dropzone.length; i+=1) {
                for (let j = 0; j < this.form.dropzone[i].files.length; j+=1) {
                    formData.append(
                        this.form.dropzone[i].getFormDescriptionName(j),
                        this.form.dropzone[i].files[j].description
                            ? this.form.dropzone[i].files[j].description
                            : ''
                    );

                    if ('id' in this.form.dropzone[i].files[j]) {
                        // If file has `id` it is already uploaded. We only add id and description data.
                        formData.append(
                            this.form.dropzone[i].getFormFileIdName(j),
                            this.form.dropzone[i].files[j].id
                        );
                    } else if ('saveFilesLocally' in options) {
                        const fileData = {
                            descriptionFieldName: this.form.dropzone[i].getFormDescriptionName(j),
                            description: this.form.dropzone[i].files[j].description
                                ? this.form.dropzone[i].files[j].description
                                : '',
                            idFieldName: this.form.dropzone[i].getFormFileIdName(j)
                        };

                        if ('id' in this.form.dropzone[i].files[j]) {
                            fileData.id = this.form.dropzone[i].files[j].id;
                        }

                        const fileId = uuidv4();

                        if (typeof this.form.dropzone[i].files[j].constructor !== 'undefined') {
                            fileData.fileFieldName = this.form.dropzone[i].getFormFileName(j);
                            fileData.file = fileId;
                        }

                        this.savedFiles[fileId] = fileData;

                        formData.append(this.form.dropzone[i].getFormFileName(j), fileId);
                        formData.append(
                            this.form.dropzone[i].getFormFileNameForLocalFiles(j),
                            JSON.stringify(fileData)
                        );
                    } else if (typeof this.form.dropzone[i].files[j].constructor !== 'undefined') {
                        // If not already uploaded
                        formData.append(
                            this.form.dropzone[i].getFormFileName(j),
                            this.form.dropzone[i].files[j]
                        );
                    }
                }
            }
        }

        if ('suggestionUI' in this.form) {
            for (let i = 0; i < this.form.suggestionUI.length; i+=1) {
                formData.append(
                    this.form.suggestionUI[i].configuration.field_name,
                    this.form.suggestionUI[i].dataOrder.join(',')
                );
            }
        }

        return formData;
    }
}