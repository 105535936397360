import Lang from '../../utils/Language';
import SvgSpriteExporter from '../../utils/SvgSpriteExporter';

function alertBox(type, message, callback)
{
    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    overlay.classList.add('is-active');
    overlay.style.position = 'fixed';

    const modalBoxElement = document.createElement('section');
    modalBoxElement.classList.add('modal-box');
    modalBoxElement.classList.add('alert-box');
    modalBoxElement.classList.add(`alert-box--${type}`);

    let icon = SvgSpriteExporter('icon_error');

    if (type === 'success') {
        icon = SvgSpriteExporter('icon_success');
    } else if (type === 'info') {
        icon = SvgSpriteExporter('icon_info');
    }

    if (message === '') {
        modalBoxElement.innerHTML = `${icon} <p>${Lang.t('common.error')}</p>`;
    } else {
        modalBoxElement.innerHTML = `${icon} <p>${message}</p>`;
    }

    const closeButton = document.createElement('a');
    closeButton.classList.add('button');
    closeButton.classList.add('button--with-loading');
    closeButton.classList.add('margin-top-2');

    closeButton.addEventListener('click', () => {
        if (typeof callback === 'function') {
            callback();
        }

        closeButton.classList.add('is-loading');
        overlay.remove();
    });

    closeButton.innerHTML = Lang.t('common.fine');
    modalBoxElement.appendChild(closeButton);

    overlay.appendChild(modalBoxElement);
    document.body.appendChild(overlay);

    modalBoxElement.classList.add('is-show');

}

function confirmBox(message, callback, cancelCallback)
{
    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    overlay.classList.add('is-active');
    overlay.style.position = 'fixed';

    const modalBoxElement = document.createElement('section');
    modalBoxElement.classList.add('modal-box');
    modalBoxElement.classList.add('alert-box');
    modalBoxElement.classList.add('alert-box--info');
    modalBoxElement.innerHTML = `${SvgSpriteExporter('icon_info')}<p>${message}</p>`;

    const buttonWrapper = document.createElement('div');
    buttonWrapper.classList.add('margin-top-2');

    const confirmButton = document.createElement('a');
    confirmButton.classList.add('button');
    confirmButton.classList.add('margin-right-1');
    confirmButton.classList.add('button--with-loading');
    confirmButton.innerHTML = Lang.t('common.yes');
    buttonWrapper.appendChild(confirmButton);

    confirmButton.addEventListener('click', () => {
        confirmButton.classList.add('is-loading');

        if (typeof callback === 'function') {
            callback();
        }

        overlay.remove();
    });

    const closeButton = document.createElement('a');
    closeButton.classList.add('button');
    closeButton.classList.add('button--secondary');
    closeButton.innerHTML = Lang.t('common.no');
    buttonWrapper.appendChild(closeButton);
    modalBoxElement.appendChild(buttonWrapper);

    closeButton.addEventListener('click', () => {
        if (typeof cancelCallback === 'function') {
            cancelCallback();
        }
        overlay.remove();
    });

    overlay.appendChild(modalBoxElement);
    document.body.appendChild(overlay);

    modalBoxElement.classList.add('is-show');
}

export { alertBox, confirmBox };
